import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`What is a charge card? How are charge cards different from credit cards? Many people think that a charge card is just a credit card. I can totally relate because I had the same misconception. In this article, we’ll explain what charge cards are and list some of the differences between charge cards and credit cards.`}</p>
    <h2>{`The Definition`}</h2>
    <p>{`A charge card is a type of payment card that requires the cardholder to pay the `}<a parentName="p" {...{
        "href": "https://credit101.net/cards/credit-card-glossary/#balance"
      }}>{`balance`}</a>{` full by each `}<a parentName="p" {...{
        "href": "https://credit101.net/cards/credit-card-glossary/#billing-cycle"
      }}>{`billing cycle`}</a>{`. Different from credit cards, charge cards have `}<a parentName="p" {...{
        "href": "https://credit101.net/cards/credit-card-glossary/#no-preset-spending-limit"
      }}>{`no pre-set spending limit`}</a>{` (NPSL). Every time you make a payment with your charge card, the transaction is approved based on your current financial standing.`}</p>
    <h2>{`How Are Charge Cards Different from Credit Cards?`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Aspect`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Charge Card`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Credit Card`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Pre-Set Spending Limit`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Transaction Approval`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Per Transaction Basis`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Based on Credit Limit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Late Payment Fees`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`APR`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Does Not Apply`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Minimum Payment`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Pay in Full`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Small Percentage of Total Balance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Build Credit`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`Do Charge Cards Build Credit?`}</h2>
    <p><img parentName="p" {...{
        "src": "https://live.staticflickr.com/7711/27031853000_4b6627aa89_o_d.jpg",
        "alt": null
      }}></img></p>
    <p><strong parentName="p">{`Yes, charge cards do help with credit-building.`}</strong>{` The most common misconception is that a charge card doesn't build credit. While it's true that most credit scoring systems (e.g. `}<a parentName="p" {...{
        "href": "https://credit101.net/score/credit-score-glossary/#fico"
      }}>{`FICO`}</a>{` don't factor charge cards into their calculation of your credit `}<a parentName="p" {...{
        "href": "https://credit101.net/score/credit-score-glossary/#utilization-ratio"
      }}>{`utilization ratio`}</a>{` (most because charge cards don't have a pre-set credit limit), other metrics like your payment history still count.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Related: `}<a parentName="p" {...{
          "href": "https://credit101.net/build/proven-ways-to-build-credit/"
        }}>{`Proven Ways to Build Credit`}</a></p>
    </blockquote>
    <h2>{`What is No Pre-Set Spending Limit?`}</h2>
    <p>{`A typical charge card doesn't have a `}<a parentName="p" {...{
        "href": "https://credit101.net/cards/credit-card-glossary/#credit-limit"
      }}>{`credit line or credit limit`}</a>{`. This unique characteristic of charge cards is known as "no pre-set spending limit" or NSPL. Despite the name might suggest otherwise, NPSL `}<strong parentName="p">{`DOES NOT`}</strong>{` mean you can use the card like crazy. Instead, each transaction is approved based on your current credit standing. You can think of it as an implicit credit limit.`}</p>
    <h2>{`Pros and Cons`}</h2>
    <p><strong parentName="p">{`Advantages`}</strong></p>
    <ul>
      <li parentName="ul">{`No Pre-set Spending Limit (NPSL)`}</li>
      <li parentName="ul">{`No Interest (because you can't carry interest)`}</li>
      <li parentName="ul">{`Build Credit`}</li>
      <li parentName="ul">{`Exclusive Membership Benefits (e.g. airline and dining credit)`}</li>
    </ul>
    <p><strong parentName="p">{`Disadvantages`}</strong></p>
    <ul>
      <li parentName="ul">{`High Annual Fees`}</li>
      <li parentName="ul">{`Can't Carry Interest (unless enrolled in Amex Pay Over Time)`}</li>
      <li parentName="ul">{`Limited Acceptance (because Amex isn't as widely accepted as Visa or Mastercard)`}</li>
      <li parentName="ul">{`Requires Fair to Excellent Credit`}</li>
    </ul>
    <h2>{`Charge Card Issuer`}</h2>
    <p><img parentName="p" {...{
        "src": "https://firebasestorage.googleapis.com/v0/b/credit101.appspot.com/o/images%2Fposts%2Fcards%2Funboxing-the-amex-rose-gold-card%2Frosegold-sheets.jpg?alt=media&token=27248071-bde6-41d1-bb81-dfdd32393688",
        "alt": "American Express Gold Card"
      }}></img></p>
    <figcaption>American Express Rose Gold Card</figcaption>
    <p>{`In the United States, the only major personal charge card issuer is American Express. Their charge card line up includes the American Express Green Card, `}<a parentName="p" {...{
        "href": "https://credit101.net/cards/unboxing-amex-rose-gold-card/"
      }}>{`Gold Card`}</a>{`, the Platinum Card, and the Centurion Card (which is quite hard to get). Please comment down below if you've discovered other charge cards you can get in the US.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`The Bottom Line`}</h2>
    <p>{`Charge cards used to be a very popular type of payment card. Nowadays, it's being gradually phased out by many major card issuers. The ability to carry balances with credit cards might eventually make charge cards obsolete, despite the many benefits like NPSL. When choosing between credit cards and charge cards, one must be able to determine if the benefits that come with the card outweigh the cost. If you can justify the high annual fee of charge cards, apply when you still can. In the meantime, you can take a look at the unboxing of my first charge card — Amex Rose Gold Card.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Related: `}<a parentName="p" {...{
          "href": "https://credit101.net/cards/unboxing-amex-rose-gold-card/"
        }}>{`Unboxing the Amex Rose Gold Card`}</a></p>
    </blockquote>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      